import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Label, InputGroup, Modal, ModalBody, UncontrolledPopover, PopoverBody } from 'reactstrap';
import FormGroup from 'library/common/components/FormGroupComponent';
import './styles.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { images } from '../../../../../../../../../library/common/constants/ImageConstants';
import ToggleSwitch from '../../../../../../../../../library/common/components/Toggle/ToggleSwitch';
import FileUpload from '../../../../../../../../../library/common/components/FileUpload';
import LotLimiteModal from './lotLimitModal';
import ImageDisplay from '../../../../../../../../../library/common/components/FileUpload/frames/ImageDisplay';
import { URLS } from '../../../../../../../../../library/common/constants/UrlConstants';
import axiosInstance from '../../../../../../../../../main/axios';
import { useForm } from 'react-hook-form';
import { Errors } from '../../../../../../../../../library/common/constants/ErrorMessage';
import { fetchFromStorage } from '../../../../../../../../../utility';
import { identifiers } from '../../../../../../../../../library/common/constants/IdentifierConstants';
import PageLoader from '../../../../../../../../../library/common/components/PageLoader';
// import ActionMessage from '../../../../../../../../../library/common/components/ActionMessage';
import { setApiMessage } from '../../../../../../../../../library/common/constants/function';
import { getFeatureData, dashBoardData } from '../../../../../../../../../library/common/actions/FeatureAction';
import { connect } from 'react-redux';
import DeleteLotModal from './DeleteLotModal';
// import MarkAsReadModal from './Frame/MarkAsRead/MarkAsReadModal';
import MarkAsReadModal from '../../Frame/MarkAsRead/MarkAsReadModal';
// import { useParams } from 'react-router-dom';
// import { EventEmitter } from '../../../../../../../../../library/common/constants/event';
import { EventEmitter } from 'library/common/constants/event';
// import 'react-toastify/dist/ReactToastify.css';
import { SocketContext } from '../../../../../../../../../main/context/socket';
import moment from 'moment';

// const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const AddItemModal = ({
  isToggle,
  toggle,
  isUpdate,
  data,
  getFeatureData,
  featureAuctionData,
  categoryList,
  getDonadationData,
  closeModal,
  dashBoardData,
  currency,
  eventFeatureOption,
  lotNumberDisable,
  // isEventStatus,
  isModal,
  currentPage,
  list,
  dropDownvalue,
  feturedData,
  isUpdates,
}) => {
  // const params = useParams();
  const [dragId, setDragId] = useState();
  const [loading, setLoading] = useState(false);
  const [chars_left, setChars_left] = useState(2000);
  const [chars_left_name, setChars_left_name] = useState(75);
  const [chars_exceed, setChars_exceed] = useState(false);
  const [formData, setFormValue] = useState({});
  // const [files, setFiles] = useState([]);
  const [switchVal, setSwitchVal] = useState(isUpdate ? data?.notifyUsers : false);
  const [switchValue, setSwitchValue] = useState(data?.featuredItem ? data?.featuredItem : false);
  const [imageArray, setImageArray] = useState(isUpdate ? data?.images : []);
  const [imageLength, setImageLength] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [markAsPaid, setMarkIsPaid] = useState(false);
  const [amount, setAmount] = useState(0);
  const [markAspaid, setMarkaspaid] = useState(false);
  const [lot_number, setLot_number] = useState('');
  const [toggleVal, setToggleValue] = useState(false);
  const [islimitToggle, setLimitToggle] = useState(false);
  // const [imageArrayList, setImageArrayList] = useState([]);
  const [finalImageArray, setFinalImageArray] = useState([]);
  const [isClaimed, setClaimed] = useState(false);
  const [isImage, setImage] = useState(false);
  const [isClaimedStatus, setIsClaimedStatus] = useState(false);
  const [isMarkPaidStatus, setIsMarkPaidStatus] = useState(false);
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  let latestImageArray = [
    { name: '', displayOrder: 1, preview: '', id: 'Box-1' },
    { name: '', displayOrder: 2, preview: '', id: 'Box-2' },
    { name: '', displayOrder: 3, preview: '', id: 'Box-3' },
    { name: '', displayOrder: 4, preview: '', id: 'Box-4' },
    { name: '', displayOrder: 5, preview: '', id: 'Box-5' },
    { name: '', displayOrder: 6, preview: '', id: 'Box-6' },
  ];

  // const [otherCategoryData, setOtherCategory] = useState('');
  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });
  var defaultValue = {
    lotname: data?.itemName !== null ? data?.itemName : '',
    automaticdraw: data?.itemAbout !== null ? data?.itemAbout : '',
    providedBy: data?.providedBy,
    lotprovidername: data?.itemProvidedByName !== null ? data?.itemProvidedByName : '',
    startingbid: data?.startBid !== null ? parseFloat(data?.startBid).toFixed(2) : '',
    increment: data?.bidIncrement !== null ? parseFloat(data?.bidIncrement).toFixed(2) : '',
    reverse_amount: data?.reserveAmount !== null ? parseFloat(data?.reserveAmount).toFixed(2) : '',
    buyitnow: data && data?.buyItNow !== null ? parseFloat(data?.buyItNow).toFixed(2) : '',
    postage_handlingamt: data && data?.postageAndHandeling !== null ? parseFloat(data?.postageAndHandeling).toFixed(2) : '',
    categoryId: data?.categoryId,
    postage_handlingnotes: data?.postageHandlingNote !== null ? data?.postageHandlingNote : '',
    lotnumber: data?.lotNumber,
    messageUsers: data?.message,
    duplicateLot: data?.totalDuplicateLot,
  };
  // console.log('isUpdateisUpdate', isUpdate);
  const {
    handleSubmit,
    watch,
    formState: { errors },
    register,
    reset,
  } = useForm({ defaultValues: defaultValue });
  // const handleToggle = () => setPopoverOpen(!popoverOpen);
  // const handle_Toggle = () => set_PopoverOpen(!popover_Open);
  const handleSwitchVal = val => {
    setSwitchVal(val);
  };
  const handleSwitchValue = val => {
    if (feturedData && feturedData >= 6) {
      if (switchValue) {
        setSwitchValue(val);
      } else {
        setLimitToggle(!islimitToggle);
      }
    } else {
      setSwitchValue(val);
    }

    // if (switchVal) {
    // } else {
    //   setSwitchValue(val);
    // }
    // if (switchVal && feturedData && feturedData >= 6) {
    //   setLimitToggle(!islimitToggle);
    // } else {
    //   setSwitchValue(val);
    // }
  };
  useEffect(() => {
    reset(defaultValue);

    // setMarkIsPaid(data?.markAsPaid);
    setAmount(data?.amountPaid !== undefined ? data?.amountPaid : 0);
    setMarkaspaid(data?.markAsPaid !== undefined ? data?.markAsPaid : false);
    setClaimed(data?.markAsClaimed !== undefined ? data?.markAsClaimed : false);
    // setIsClaimedStatus(data?.markAsClaimed);
    // setIsMarkPaidStatus(data?.markAsPaid);
  }, [data]);

  useEffect(() => {
    const eventDetails = fetchFromStorage(identifiers.eventDetails);
    getFeatureData(eventDetails?.id, 'auction');
    var lot_number =
      featureAuctionData && featureAuctionData[0]?.eventFeatureOptions?.filter(item => item.key === 'lotnotype');
    setLot_number(lot_number);
    var finalImage_Array = [...latestImageArray];
    if (data?.images !== undefined) {
      if (data?.images?.length > 0) {
        data.images.map((item, i) => {
          var boxId = `Box-${i + 1}`;
          const tempObj = {
            name: identifiers.imageUrlConstant + item?.name,
            preview: identifiers.imageUrlConstant + item?.name,
            displayOrder: item?.displayOrder,
            imagename: item?.name,
            id: boxId,
          };
          var fIndex = latestImageArray.findIndex(e => e.displayOrder === tempObj?.displayOrder);
          finalImage_Array[fIndex] = tempObj;
          return item;
        });
      }
    }
    setFinalImageArray(finalImage_Array);

    isUpdate !== true &&
      EventEmitter.subscribe('removeauctionlotimage', event => {
        setFinalImageArray(latestImageArray);
      });
  }, [data]);
  // console.log('isUpdate', finalImageArray, imageArray);
  const handleLimit = event => {
    const valueLength = 2000 - event.target.value.length;
    setChars_left(valueLength);
    const charExceed = valueLength > 2000 ? true : false;
    setChars_exceed(charExceed);
    // console.log('valueLength', valueLength);
  };
  const handleLimitName = event => {
    const valueLength = 75 - event.target.value.length;
    setChars_left_name(valueLength);
    const charExceed = valueLength > 75 ? true : false;
    // setChars_exceed(charExceed);
  };
  const handleAmountChange = e => {
    const value = e.target.value;
    setAmount(value);
  };
  const validateReserveAmount = () => {
    // console.log('check', watch('buyitnow'), watch('reverse_amount'));
    if (parseInt(watch('reverse_amount')) < parseInt(watch('startingbid'))) {
      return OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT_MUST_GREATER;
    }
    return true;
  };
  const validateBuyItNowAmount = () => {
    // console.log('check', watch('buyitnow'), watch('reverse_amount'));
    if (parseInt(watch('buyitnow')) !== 0 && parseInt(watch('buyitnow')) < parseInt(watch('reverse_amount'))) {
      return OKTION_VARIABLE?.OKTION_BUYITNOW_AMOUNT_MUST_GREATER;
    }
    return true;
  };
  const handleConfirm = val => {
    // console.log('valll', val);
    setMarkaspaid(val);
    handleMarkAsPaid();
    setToggleValue(val);
    setToggleValue(false);
    setIsMarkPaidStatus(true);
  };
  const handleMediaTypeChange = (file, index) => event => {
    // const { value } = event.target;
    // const filesArray = [...files];
    // filesArray[index].mediaType = value;
    // this.setState({ files: filesArray });
  };
  const handleFileUpload = (file, name, index) => {
    if (file[0] && file[0].size <= 10485760) {
      const filesArray = file?.map(file =>
        Object.assign(file, {
          mediaType: 'other',
        }),
      );
      const newFiles = [...filesArray];
      // setFiles({ ...files, [name]: newFiles });
      uploadImage(file, index);
      setImageLength(false);
    } else {
      setImageLength(true);
    }
  };
  const toggleLotDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
    // var eventDetails = fetchFromStorage(identifiers.eventDetails);
    // getDonadationData();
    // dashBoardData(eventDetails?.id);
    // closeModal();
  };
  const uploadImage = async (file, index) => {
    setLoading(true);
    const body = new FormData();
    const method = 'post';
    const url = URLS.multipleImageUpload;
    for (let i = 0; i < file.length; i++) {
      body.append('file', file[i]);
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { status, data } = await axiosInstance[method](url, body, config);
      if (status === 201) {
        setLoading(false);
        var IMAGE_ARRAY = data?.files;
        var finalImage_Array = [...finalImageArray];
        var INDEX = index;
        IMAGE_ARRAY.map((item, i) => {
          const tempObj = {
            name: identifiers.imageUrlConstant + item,
            preview: identifiers.imageUrlConstant + item,
            displayOrder: INDEX + 1,
            imagename: item,
            id: `Box-${INDEX + 1}`,
          };
          INDEX = INDEX + 1;
          var fIndex = finalImage_Array.findIndex(e => e.displayOrder === tempObj?.displayOrder);
          finalImage_Array[fIndex] = tempObj;
          return item;
        });
        setFinalImageArray(finalImage_Array);
        var image_Length = finalImage_Array.filter(item => item?.name !== '');
        setImage(image_Length?.length === 0 ? true : false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const handleRemoveImage = element => () => {
    var filteredArray = [];
    finalImageArray.map((item, index) => {
      var boxId = `Box-${index + 1}`;
      const tempObj = {
        name: item.displayOrder === element?.displayOrder ? '' : item?.name,
        preview: item.displayOrder === element?.displayOrder ? '' : item?.preview,
        imagename: item.displayOrder === element?.displayOrder ? '' : item?.imagename,
        displayOrder: item.displayOrder,
        id: boxId,
      };
      filteredArray[index] = tempObj;
      return item;
    });
    setFinalImageArray(filteredArray);
    var image_Length = filteredArray.filter(item => item?.name !== '');
    setImage(image_Length?.length === 0 ? true : false);
  };
  const handleInputChange = event => {
    var name = event.target.name;
    setFormValue({ ...formData, [name]: event.target.value });
  };
  const handleMarkAsPaid = () => {
    setMarkIsPaid(!markAsPaid);
    setToggleValue(!toggleVal);
  };

  const onSubmit = async (dataValue, addstatus) => {
    // console.log('addstatusaddstatus', addstatus);
    var finalImage_Array = finalImageArray?.filter(item => item?.name !== '');
    finalImage_Array.map((item, i) => {
      const tempObj = {
        name: item?.imagename,
        displayOrder: item?.displayOrder,
      };
      finalImage_Array[i] = tempObj;
      return item;
    });

    var {
      lotname,
      lotnumber,
      automaticdraw,
      providedBy,
      lotprovidername,
      startingbid,
      increment,
      reverse_amount,
      buyitnow,
      postage_handlingamt,
      postage_handlingnotes,
      duplicateLot,
      categoryId,
      othercategory,
      messageUsers,
    } = watch();
    var eventDetails = fetchFromStorage(identifiers.eventDetails);

    if (categoryId === 'other') {
      var requestData = {
        categoryName: othercategory,
        // url: 'string',
        eventId: eventDetails?.id,
      };
      const { data } = await axiosInstance.post(URLS.getCategory, requestData);
      var otherCatId = data?.data?.createdCategory?.id;
    }
    var categoryValue = categoryId === 'other' ? otherCatId : categoryId;
    var lotnotype =
      featureAuctionData && featureAuctionData[0]?.eventFeatureOptions?.find(item => item.key === 'lotnotype');

    var sendData = {
      type: 'auction',
      eventId: eventDetails?.id,
      // status: isUpdate ? data?.status : addstatus,
      status: featureAuctionData[0]?.status,
      auctionOptions: [
        {
          ...(isUpdate && { id: data?.id }),
          itemName: lotname,
          ...(lotnotype?.value !== 'sequential' && {
            lotNumber: lotnumber !== undefined && lotnumber !== null && lotnumber !== '' ? lotnumber : '',
          }),
          itemAbout: automaticdraw,
          providedBy: providedBy,
          itemProvidedByName: lotprovidername,
          // images: imageArray,
          images: finalImage_Array,
          ...(startingbid !== '' && { startBid: startingbid === '' ? 0 : startingbid }),
          ...(increment !== '' && { bidIncrement: increment === '' ? 0 : increment }),
          ...(reverse_amount !== '' && { reserveAmount: reverse_amount === '' ? 0 : reverse_amount }),
          ...(buyitnow !== '' && { buyItNow: buyitnow === '' ? 0 : buyitnow }),
          ...(postage_handlingamt !== '' && { postageAndHandeling: postage_handlingamt }),
          ...(postage_handlingnotes !== '' && { postageHandlingNote: postage_handlingnotes }),
          featuredItem: switchValue,
          notifyUsers: switchVal,
          message: switchVal === true ? messageUsers : '',
          markAsClaimed: isClaimed,
          markAsPaid: markAspaid,
          amountPaid: Number(amount),
          status: addstatus,
          duplicateLots: duplicateLot === '' ? 0 : Number(duplicateLot),
          ...(categoryValue !== '' && { categoryId: categoryId === 'other' ? otherCatId : categoryId }),
          winnerId: data && data?.winnerId !== '' ? data?.winnerId : null,
          reserveMet: data && data?.reserveMet !== '' ? data?.reserveMet : '',
        },
      ],
    };
    var isCheckImageValidation = true;
    if (addstatus === 'published') {
      isCheckImageValidation = imageArray.length > 0 ? true : false;
    }
    // if (isCheckImageValidation) {
    if (!isImage) {
      setLoading(true);
      try {
        const { status, data } = await axiosInstance.put(URLS.deleteFeature(featureAuctionData[0]?.id), sendData);
        if (status === 200 || status === 304) {
          setDeleteModalOpen(false);
          setLoading(false);
          getDonadationData(dropDownvalue, currentPage, list);
          dashBoardData(eventDetails?.id);
          getFeatureData(eventDetails?.id, 'auction');
          // setActionMessage(true, 'Success', data.msg, data.data);
          window.scrollTo(0, 0);
          /*  let $el = $('#modalIds').scrollTop(0);
          function anim() {
            var st = $el.scrollTop();
            var sb = $el.prop('scrollHeight') - $el.innerHeight();
            $el.animate({ scrollTop: st < sb / 2 ? sb : 0 }, 1000, anim);
          }
          anim(); */

          setApiMessage(
            'success',
            addstatus === 'deleted'
              ? OKTION_VARIABLE?.OKTION_LOT_DELETE
              : (addstatus === 'published' || addstatus === 'saved') && isUpdate !== true
                ? OKTION_VARIABLE?.OKTION_LOT_ADDED
                : isUpdate === true
                  ? OKTION_VARIABLE?.OKTION_LOT_UPDATED
                  : data.msg,
          );

          // toast.success(
          //   <>
          //     <img src={images.checkIcon} alt="successIcon" /> {data.msg}
          //   </>,
          //   {
          //     position: 'top-center',
          //     autoClose: 3000,
          //     hideProgressBar: true,
          //     closeOnClick: false,
          //     pauseOnHover: false,
          //     draggable: false,
          //     progress: undefined,
          //     closeButton: false,
          //     draggable: false,
          //   },
          // );
          // notify();
          setTimeout(() => {
            // setActionMessage(false);
            isModal && closeModal();
            if (isUpdate !== true) {
              setFinalImageArray(latestImageArray);
              setSwitchValue(false);
              // setFiles([]);
              // setImageArrayList([]);
              // setImageArray([]);
            }
          }, 4000);
        }
      } catch (err) {
        // setDeleteModalOpen(false);
        // setLoading(false);
        // getDonadationData();
        // dashBoardData(eventDetails?.id);
        // closeModal();
        // setActionMessage(true, 'Success', data.msg, data.data);
        // setTimeout(() => {
        //   setActionMessage(false);
        // }, 2000);
        setLoading(false);
        if (err.response) {
          if (err?.response?.data?.msg) {
            var errorMessage = err?.response?.data?.msg;
            setApiMessage('error', errorMessage);
            // setActionMessage(true, 'Error', errorMessage);
          } else {
            var errorMessage =
              err?.response?.data?.validation && err?.response?.data?.validation['body']?.message !== undefined
                ? err?.response?.data?.validation['body']?.message
                : OKTION_VARIABLE?.OKTION_DONATION_CHANGES_NOT_SUCCESSFULLY;
            // setActionMessage(true, 'Error', errorMessage);
            setApiMessage('error', errorMessage);
            // toast.error(
            //   <>
            //     <img src={images.infoIcon} alt="infoIcon" /> {errorMessage}
            //   </>,
            //   {
            //     position: 'top-center',
            //     autoClose: 3000,
            //     hideProgressBar: true,
            //     closeOnClick: false,
            //     pauseOnHover: false,
            //     draggable: false,
            //     progress: undefined,
            //     closeButton: false,
            //     draggable: false,
            //   },
            // );
          }
        } else {
          if (err.response) {
            // setActionMessage(true, 'Error', 'Something went wrong');
            setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
            // toast.error(
            //   <>
            //     <img src={images.infoIcon} alt="infoIcon" /> Something went wrong
            //   </>,
            //   {
            //     position: 'top-center',
            //     autoClose: 3000,
            //     hideProgressBar: true,
            //     closeOnClick: false,
            //     pauseOnHover: false,
            //     draggable: false,
            //     progress: undefined,
            //     closeButton: false,
            //     draggable: false,
            //   },
            // );
          }
        }
        // setTimeout(() => {
        //   setActionMessage(false);
        // }, 4000);
      }
    } else {
      // setActionMessage(true, 'Error', 'At lease one image is required');
      setApiMessage('error', OKTION_VARIABLE?.OKTION_IMAGE_VALIDATION_MSG);
      // toast.error(
      //   <>
      //     <img src={images.infoIcon} alt="infoIcon" /> {OKTION_VARIABLE?.OKTION_IMAGE_VALIDATION_MSG}
      //   </>,
      //   {
      //     position: 'top-center',
      //     autoClose: 3000,
      //     hideProgressBar: true,
      //     closeOnClick: false,
      //     pauseOnHover: false,
      //     draggable: false,
      //     progress: undefined,
      //     closeButton: false,
      //     draggable: false,
      //   },
      // );
      // setTimeout(() => {
      //   setActionMessage(false);
      // }, 4000);
    }
    setIsClaimedStatus(false);
    setIsMarkPaidStatus(false);
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };
  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };
  const handleClose = () => {
    if (isUpdate !== true) {
      // setFiles([]);
      setImageArray([]);
      setFinalImageArray(latestImageArray);
    }
    setIsClaimedStatus(false);
    setIsMarkPaidStatus(false);
  };

  const handleDrag = ev => {
    setDragId(ev.currentTarget.id);
  };

  const handleEventStatus = () => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    let current_time = moment(new Date()).format('HHmm');
    const eventDetails = fetchFromStorage(identifiers.eventDetails);
    // console.log('eventDetailseventDetails', eventDetails);

    if (eventDetails?.status === 'saved') {
      return 'Draft';
      // setEventStatus('Draft');
    } else {
      let eventEndDate = moment(eventDetails?.endDate).format('YYYY-MM-DD');
      let eventEndTime = moment(eventDetails?.endDate).format('HHmm');
      if (moment(today).isAfter(eventEndDate)) {
        return 'Closed';
        // setEventStatus('Closed');
      } else {
        if (today === eventEndDate && eventEndTime < current_time) {
          return 'Closed';
          // setEventStatus('Closed');
        } else {
          return 'Active';
          // setEventStatus('Active');
        }
      }
    }
  };
  const handleClaimed = async () => {
    setClaimed(true);
    setIsClaimedStatus(true);
  };
  const handleDrop = ev => {
    const dragBox = finalImageArray.find(box => box.id === dragId);
    const dropBox = finalImageArray.find(box => box.id === ev.currentTarget.id);
    const dragBoxOrder = dragBox.displayOrder;
    const dropBoxOrder = dropBox.displayOrder;

    const newBoxState = finalImageArray.map(box => {
      if (box.id === dragId) {
        box.displayOrder = dropBoxOrder;
      }
      if (box.id === ev.currentTarget.id) {
        box.displayOrder = dragBoxOrder;
      }
      return box;
    });
    setFinalImageArray(newBoxState);
  };

  var isEventStatus = handleEventStatus();
  var isValid = lot_number && lot_number[0]?.value === 'customised' ? true : false;

  const disableReservAmt = data?.currentBid >= 1;
  const isFieldDisable = (data?.supplyItemId !== null || data?.markAsPaid || disableReservAmt) && isUpdate;

  const isSupplyFieldDisable =
    data?.supplyItemId !== null && !data?.markAsPaid
      ? false
      : data?.supplyItemId !== null && data?.markAsPaid
        ? true
        : data?.supplyItemId === null && data?.markAsPaid === false
          ? false
          : true;
  // const isSupplyFieldDisable = data?.markAsPaid && isUpdate;
  var lotnotype =
    featureAuctionData && featureAuctionData[0]?.eventFeatureOptions?.find(item => item.key === 'lotnotype');

  // const myRef = useRef(null);
  var image_Length = finalImageArray.filter(item => item?.name !== '');
  // const executeScroll = () => scrollToRef(myRef);

  const eventFeatureStatus = () => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    let current_time = moment(new Date()).format('HHmm');

    if (featureAuctionData?.[0]?.status === 'saved') {
      return 'Draft';
    } else {
      let featureEndDate = moment(featureAuctionData?.[0]?.endTime).format('YYYY-MM-DD');
      let featureEndTime = moment(featureAuctionData?.[0]?.endTime).format('HHmm');
      if (moment(today).isAfter(featureEndDate)) {
        return 'Closed';
      } else {
        if (today === featureEndDate && featureEndTime < current_time) {
          return 'Closed';
        } else {
          return 'Active';
        }
      }
    }
  };

  return (
    <>
      {' '}
      {/* <ToastContainer /> */}
      <div className="AddPriceModal AddItemModal " id="modalIds">
        {/* {loading && <PageLoader />} */}
        <Modal size="lg" className="customModal add-price-modal maxwidth-900 addLotModal" isOpen={isToggle}>
          {loading && <PageLoader />}
          <div className="text-end" onClick={() => handleClose()}>
            <img className="close-icon cursor-pointer" onClick={toggle} src={images.close} alt="" />
          </div>
          <div className="d-flex modalHeader">
            <div className="title-unclaimedIcon">
              <h2>
                {isUpdate ? OKTION_VARIABLE?.OKTION_EDIT : OKTION_VARIABLE?.OKTION_ADD}
                {OKTION_VARIABLE?.OKTION_LOT}
              </h2>
              {isUpdate ? (
                <div>
                  <img
                    src={
                      data?.markAsClaimed === true || isClaimed === true
                        ? images.claimed
                        : data?.markAsClaimed === false || isClaimed === false
                          ? images.unClaimed
                          : ''
                    }
                    alt="unClaimed"
                    // className={`${isFieldDisable || isEventStatus === 'Closed' ? 'cursor-default' : 'cursor-pointer'}`}
                    // className={`${data?.markAsClaimed || isClaimed ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    className={`${
                      (eventFeatureStatus() === 'Closed' || data?.isSold) &&
                      (data?.markAsClaimed === false || isClaimed === false)
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed'
                    }`}
                    onClick={() => {
                      //  handleClaimed();
                      // if (!isFieldDisable && isEventStatus !== 'Closed') {
                      // eventFeatureStatus() === 'Closed' ? false : data?.isSold ? false : true ? true : false
                      if (
                        (eventFeatureStatus() === 'Closed' || data?.isSold) &&
                        (data?.markAsClaimed === false || isClaimed === false)
                      ) {
                        handleClaimed();
                      }
                      // }
                    }}
                    // disabled={isFieldDisable || isEventStatus === 'Closed' ? true : false}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          {/* <div className="d-flex modalHeader">
            <div>
              <h2>
                {isUpdate ? OKTION_VARIABLE?.OKTION_EDIT : OKTION_VARIABLE?.OKTION_ADD}
                {OKTION_VARIABLE?.OKTION_LOT}
              </h2>
            </div>
          </div> */}
          <ModalBody>
            <div className="addItemModalForm">
              {/* {message.display && (
                <div className="btn-block" {...myRef}>
                  <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
                </div>
              )} */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="lot-Images-block">
                  {finalImageArray
                    .sort((a, b) => a.displayOrder - b.displayOrder)
                    .map((item, index) => (
                      <Col
                        sm="4"
                        xs="12"
                        className="mb-4"
                        draggable={true}
                        id={item?.id}
                        onDragOver={ev => ev.preventDefault()}
                        onDragStart={e => {
                          // handleDrag
                          if (!isFieldDisable && isEventStatus !== 'Closed') {
                            handleDrag(e);
                          }
                        }}
                        onDrop={e => {
                          // handleDrop
                          if (!isFieldDisable && isEventStatus !== 'Closed') {
                            handleDrop(e);
                          }
                        }}>
                        {item?.name !== '' ? (
                          <ImageDisplay
                            // files={files[`uploader${index}`]}
                            files={[item]}
                            onRemove={e => handleRemoveImage(item)}
                            onChangeMediaType={handleMediaTypeChange}
                            // handleImageErrored={handleImageErrored}
                            // isImageLoaded={isImageLoaded}
                            disabled={isFieldDisable || isEventStatus === 'Closed' ? true : false}
                          />
                        ) : (
                          <FileUpload
                            accept="image/jpeg, image/png"
                            text={OKTION_VARIABLE?.OKTION_UPLOAD_LOT_IMAGE}
                            name={`uploader${index}`}
                            onDrop={file => handleFileUpload(file, `uploader${index}`, index)}
                            multiple
                            disabled={isFieldDisable || isEventStatus === 'Closed' ? true : false}
                          />
                        )}
                      </Col>
                    ))}
                  <Col>
                    {imageLength === true && <p className="text-danger">{OKTION_VARIABLE?.OKTION_IMAGE_VALIDATION}</p>}
                    {isImage === true && (
                      <span className="text-danger mt-2">
                        {OKTION_VARIABLE?.OKTION_PLEASE_SELECT_ATMEASE_ONE_IMAGE}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row className="img-specification">
                  <Col sm="12" className="mb-5">
                    <div className="message">
                      <p>{OKTION_VARIABLE?.OKTION_IMAGE_SPECIFICATION}</p>
                      <p>{OKTION_VARIABLE?.OKTION_UPLOAD_UPTO_SIX}</p>
                      <p>{OKTION_VARIABLE?.OKTION_FILE_TYPE}</p>
                      <p>{OKTION_VARIABLE?.OKTION_RECOMMENDED_DIMENSION}</p>
                      <p>{OKTION_VARIABLE?.OKTION_FILE_SIZE}</p>
                    </div>
                  </Col>
                </Row>
                <FormGroup
                  className={
                    watch('lotname') !== '' && typeof watch('lotname') !== 'undefined'
                      ? ' mb-0 focus_field fixed-fieldset'
                      : ' mb-0 focus_field '
                  }>
                  <input
                    type="text"
                    {...register('lotname', {
                      required: true,
                      maxLength: 75,
                      onChange: e => {
                        handleLimitName(e);
                        handleInputChange(e);
                      },
                    })}
                    // onChange={e => {
                    //   handleLimitName(e);
                    //   handleInputChange(e);
                    // }}
                    className={`mb-0 form-control ${errors.lotname && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_LOT_NAME_REQUIRED}
                    name="lotname"
                    disabled={isFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_LOT_NAME_REQUIRED}</label>
                  {errors.lotname && chars_left_name > 0 && <span className="text-danger">{Errors.mandatory}</span>}
                  {chars_left_name < 0 && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_LOTNAME_GREATER}</span>
                  )}
                  <p className="char_limit mt-0">{chars_left_name}</p>
                </FormGroup>
                <FormGroup
                  className={
                    watch('automaticdraw') !== '' && typeof watch('automaticdraw') !== 'undefined'
                      ? ' mb-0 focus_field fixed-fieldset'
                      : ' mb-0 focus_field '
                  }>
                  <textarea
                    {...register('automaticdraw', {
                      required: true,
                      onChange: e => {
                        handleLimit(e);
                        handleInputChange(e);
                      },
                    })}
                    // onChange={e => {
                    //   handleLimit(e);
                    //   handleInputChange(e);
                    // }}
                    name="automaticdraw"
                    type="textarea"
                    className={`mb-0 form-control ${errors.automaticdraw && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_ABOUT_THIS_LOT}
                    disabled={isFieldDisable}
                    data-gramm_editor="false"
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_ABOUT_THIS_LOT}</label>
                  {errors.automaticdraw && chars_left > 0 && <span className="text-danger">{Errors.mandatory}</span>}
                  {chars_left < 0 && <span className="text-danger">{OKTION_VARIABLE?.OKTION_ABOUTLOT_GREATER}</span>}
                  <p className="char_limit mt-0">{chars_left}</p>
                </FormGroup>
                {/* {console.log('lotlot', watch('lotnumber'))} */}
                <FormGroup
                  className={
                    watch('lotnumber') !== '' && typeof watch('lotnumber') !== 'undefined'
                      ? ' mb-0 focus_field fixed-fieldset'
                      : ' mb-0 focus_field '
                  }>
                  <input
                    type="text"
                    // {...register({
                    //   required: !lotNumberDisable || lot_number[0]?.value === 'customised' ? true : false,
                    //   maxLength: 75 })}
                    {...register('lotnumber', {
                      required: lotnotype?.value === 'sequential' ? false : true,
                      onChange: e => {
                        handleInputChange(e);
                      },
                    })}
                    // onChange={e => {
                    //   handleInputChange(e);
                    // }}
                    className={`mb-0 form-control ${errors.lotnumber && 'errorColor'}`}
                    placeholder={
                      lotnotype?.value === 'sequential'
                        ? OKTION_VARIABLE?.OKTION_LOT_NUMBER
                        : OKTION_VARIABLE?.OKTION_LOT_NUMBER
                    }
                    name="lotnumber"
                    disabled={lotnotype?.value === 'sequential' ? true : false || isFieldDisable}
                    // disabled={lotNumberDisable || (lot_number && lot_number[0]?.value === 'sequential') ? true : false}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_LOT_NUMBER_REQUIRED}</label>
                  {errors.lotnumber && chars_left_name > 0 && <span className="text-danger">{Errors.mandatory}</span>}
                </FormGroup>
                <FormGroup className="custome-dropdown-arrow">
                  <select
                    {...register('providedBy', { required: false })}
                    className="form-control mb-3 mt-2 cursor-pointer"
                    name="providedBy"
                    value={watch('providedBy')}
                    disabled={isFieldDisable}>
                    <option value="providedBy">{OKTION_VARIABLE?.OKTION_PROVIDED_BY}</option>
                    <option value="donatedBy">{OKTION_VARIABLE?.OKTION_DONATION_BY}</option>
                    <option value="sponsoredBy">{OKTION_VARIABLE?.OKTION_SPONSOR_BY}</option>
                  </select>

                  {/* <Input {...register} type="select" name="providedBy" id="exampleSelect">
                    <option value="providedBy">Provided by</option>
                    <option value="contributedBy">Contributed by</option>
                    <option value="sponsoredBy">Sponsored by</option>
                  </Input> */}
                </FormGroup>
                <FormGroup
                  className={
                    watch('lotprovidername') !== '' && typeof watch('lotprovidername') !== 'undefined'
                      ? ' mt-2 focus_field fixed-fieldset'
                      : ' mt-2 focus_field '
                  }>
                  <input
                    {...register('lotprovidername', { required: true, onChange: e => handleInputChange(e) })}
                    className={`mb-0 form-control ${errors.lotprovidername && 'errorColor'}`}
                    type="text"
                    placeholder={OKTION_VARIABLE?.OKTION_LOT_PROVIDER_NAME}
                    name="lotprovidername"
                    // onChange={e => handleInputChange(e)}
                    disabled={isFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_LOT_PROVIDER_NAME}</label>
                  {errors.lotprovidername && <span className="text-danger">{Errors.mandatory}</span>}
                </FormGroup>
                <FormGroup className="custome-dropdown-arrow mt-2">
                  {/* <select {...register} className="form-control mb-3 mt-2" name="providedBy" value={watch('providedBy')}> */}
                  <select
                    {...register('categoryId', { required: true })}
                    className="form-control mb-3 mt-2"
                    name="categoryId"
                    disabled={isFieldDisable}
                    // value={watch('categoryId')}
                  >
                    <option value="" hidden>
                      {OKTION_VARIABLE?.OKTION_CATEGORY}
                    </option>
                    {typeof categoryList !== 'undefined' && (
                      <>
                        {categoryList?.map((item, index) => (
                          <option value={item?.id}>{item?.categoryName}</option>
                        ))}
                        <option value="other">{OKTION_VARIABLE?.OKTION_OTHER}</option>
                      </>
                    )}
                  </select>
                  {errors.categoryId && <span className="text-danger">{Errors.mandatory}</span>}
                  {/* <option>Flights</option>
                    <option>Hotel & Travel</option>
                    <option>Cards & Banking</option>
                    <option>Shopping</option>
                    <option>Food & Wine</option>
                    <option>Health, Leisure & Entertainment</option>
                    <option>Insurance</option>
                    <option>Utilities & Services</option>
                    <option>Home</option>
                    <option>Transport</option>
                    <option>Other</option> */}
                  {/* </Input> */}
                </FormGroup>
                {/* <FormGroup className="mt-2">
                  <Input type="text" placeholder="Lot Provider Name*" />
                </FormGroup> */}
                {watch('categoryId') === 'other' && (
                  <FormGroup
                    className={
                      watch('othercategory') !== '' && typeof watch('othercategory') !== 'undefined'
                        ? ' mt-2 focus_field fixed-fieldset '
                        : ' mt-2 focus_field '
                    }>
                    <input
                      type="text"
                      {...register('othercategory', { required: true, onChange: e => handleInputChange(e) })}
                      // onChange={e => handleInputChange(e)}
                      className={`mb-0 form-control ${errors.othercategory && 'errorColor'}`}
                      placeholder={OKTION_VARIABLE?.OKTION_TYPE_ITEM_CATEGORY}
                      name="othercategory"
                      disabled={isFieldDisable}
                    />
                    <label className="label-helper">{OKTION_VARIABLE?.OKTION_TYPE_ITEM_CATEGORY}</label>
                    {errors.othercategory && <span className="text-danger">{Errors.mandatory}</span>}
                  </FormGroup>
                )}

                <InputGroup
                  className={
                    watch('startingbid') !== '' &&
                    typeof watch('startingbid') !== 'undefined' &&
                    watch('startingbid') !== null
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    {...register('startingbid', {
                      required: true,
                      min: 1,
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                        // handleAmountChange(e);
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    className={`mb-0 form-control input_field-style ${errors.startingbid && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_STARTING_BID_REQUIRED}
                    name="startingbid"
                    onKeyPress={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    type="number"
                    // onChange={e => {
                    //   const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                    //   e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                    //   // handleAmountChange(e);
                    // }}
                    // onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                    disabled={isSupplyFieldDisable || disableReservAmt}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_STARTING_BID_REQUIRED}</label>
                  <span className="span-style">{currency}</span>
                  {errors.startingbid && errors?.startingbid?.type === 'required' && (
                    <span className="text-danger">{Errors.mandatory}</span>
                  )}
                  {errors.startingbid && errors?.startingbid?.type === 'min' && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_VALUE_GRATER}</span>
                  )}
                  {/* {console.log('checkerror', errors)} */}
                </InputGroup>
                <InputGroup
                  className={
                    watch('increment') !== '' &&
                    typeof watch('increment') !== 'undefined' &&
                    watch('increment') !== null
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    className={`mb-0 form-control input_field-style ${errors.increment && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_INCREMENT_REQUIRED}
                    {...register('increment', {
                      required: true,
                      min: 1,
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                        handleInputChange(e);
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    name="increment"
                    // onChange={e => {
                    //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    //   handleInputChange(e);
                    // }}
                    type="number"
                    onKeyPress={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    // onChange={e => {
                    //   const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                    //   e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                    //   handleInputChange(e);
                    // }}
                    // onKeyPress={event => {
                    //   if (
                    //     (event.which != 46 || event.target.value.indexOf('.') != -1) &&
                    //     (event.which < 48 || event.which > 57)
                    //   ) {
                    //     event.preventDefault();
                    //   }
                    //   if (event.target.value.indexOf('.') > -1 && event.target.value.split('.')[1].length > 1) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    // onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                    disabled={isSupplyFieldDisable || disableReservAmt}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_INCREMENT_REQUIRED}</label>
                  <span className="span-style">{currency}</span>
                  {errors.increment && errors?.increment?.type === 'required' && (
                    <span className="text-danger">{Errors.mandatory}</span>
                  )}
                  {errors.increment && errors?.increment?.type === 'min' && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_VALUE_GRATER}</span>
                  )}
                </InputGroup>
                <InputGroup
                  className={
                    watch('reverse_amount') !== '' &&
                    typeof watch('reverse_amount') !== 'undefined' &&
                    watch('reverse_amount') !== null
                      ? ' mb-3 form-group-style focus_field from-group-with-span fixed-fieldset'
                      : ' form-group-style mb-3 focus_field from-group-with-span '
                  }>
                  <input
                    className={`mb-0 form-control input_field-style ${errors.reverse_amount && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT_REQUIRED}
                    {...register('reverse_amount', {
                      required: true,
                      validate: validateReserveAmount,
                      min: 1,
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                        handleInputChange(e);
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    name="reverse_amount"
                    type="number"
                    onKeyPress={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    // onChange={e => {
                    //   const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                    //   e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                    //   handleInputChange(e);
                    // }}
                    // onKeyPress={event => {
                    //   if (
                    //     (event.which != 46 || event.target.value.indexOf('.') != -1) &&
                    //     (event.which < 48 || event.which > 57)
                    //   ) {
                    //     event.preventDefault();
                    //   }
                    //   if (event.target.value.indexOf('.') > -1 && event.target.value.split('.')[1].length > 1) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    // onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                    disabled={isFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT_REQUIRED}</label>
                  <span className="span-style">{currency}</span>
                  {/* {errors.reverse_amount && <span className="text-danger">{Errors.mandatory}</span>} */}
                  {errors.reverse_amount && errors?.reverse_amount?.type === 'required' && (
                    <span className="text-danger">{Errors.mandatory}</span>
                  )}
                  {errors.reverse_amount && errors?.reverse_amount?.type === 'validate' && (
                    <span className="text-danger">{errors?.reverse_amount?.message}</span>
                  )}
                  {errors.reverse_amount && errors?.reverse_amount?.type === 'min' && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_VALUE_GRATER}</span>
                  )}
                </InputGroup>
                <InputGroup
                  className={
                    watch('buyitnow') !== '' && typeof watch('buyitnow') !== 'undefined' && watch('buyitnow') !== null
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    className={`mb-0 form-control input_field-style ${errors.buyitnow && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_BUYITNOW_AMOUNT}
                    {...register('buyitnow', {
                      required: false,
                      validate: validateBuyItNowAmount,
                      min: 0,
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                        handleInputChange(e);
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    type="number"
                    onKeyPress={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    // onChange={e => {
                    //   const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                    //   e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                    //   handleInputChange(e);
                    // }}
                    // onKeyPress={event => {
                    //   if (
                    //     (event.which != 46 || event.target.value.indexOf('.') != -1) &&
                    //     (event.which < 48 || event.which > 57)
                    //   ) {
                    //     event.preventDefault();
                    //   }
                    //   if (event.target.value.indexOf('.') > -1 && event.target.value.split('.')[1].length > 1) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                    name="buyitnow"
                    disabled={isSupplyFieldDisable || disableReservAmt}
                  />
                  {/* {console.log('errorserrors', errors)} */}
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_BUYITNOW_AMOUNT}</label>
                  <span className="span-style">{currency}</span>
                  {errors.buyitnow && errors?.buyitnow?.type === 'required' && (
                    <span className="text-danger">{Errors.mandatory}</span>
                  )}
                  {errors.buyitnow && errors?.buyitnow?.type === 'validate' && (
                    <span className="text-danger">{errors?.buyitnow?.message}</span>
                  )}
                  {errors.buyitnow && errors?.buyitnow?.type === 'min' && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_VALUE_GRATER}</span>
                  )}
                </InputGroup>
                <InputGroup
                  className={
                    watch('postage_handlingamt') !== '' &&
                    typeof watch('postage_handlingamt') !== 'undefined' &&
                    watch('postage_handlingamt') !== null
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    className={`mb-0 form-control input_field-style ${errors.postage_handlingamt && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_POSTAGE_HANDLING_AMOUNT}
                    // {...register({ required: true })}
                    {...register('postage_handlingamt', {
                      min: 0,
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                        handleInputChange(e);
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    name="postage_handlingamt"
                    type="number"
                    onKeyPress={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    // onChange={e => {
                    //   const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                    //   e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                    //   handleInputChange(e);
                    // }}
                    // onKeyPress={event => {
                    //   if (
                    //     (event.which != 46 || event.target.value.indexOf('.') != -1) &&
                    //     (event.which < 48 || event.which > 57)
                    //   ) {
                    //     event.preventDefault();
                    //   }
                    //   if (event.target.value.indexOf('.') > -1 && event.target.value.split('.')[1].length > 1) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    // onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                    disabled={isFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_POSTAGE_HANDLING_AMOUNT}</label>
                  <span className="span-style">{currency}</span>
                  {errors.postage_handlingamt && <span className="text-danger">{Errors.mandatory}</span>}
                  {errors.postage_handlingamt && errors?.postage_handlingamt?.type === 'min' && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_VALUE_GRATER}</span>
                  )}
                </InputGroup>
                <FormGroup
                  className={
                    watch('postage_handlingnotes') !== '' && typeof watch('postage_handlingnotes') !== 'undefined'
                      ? ' mt-2 focus_field fixed-fieldset'
                      : ' mt-2 focus_field '
                  }>
                  <input
                    type="text"
                    className={`mb-0 form-control ${errors.postage_handlingnotes && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_POSTAGE_NOTES}
                    {...register('postage_handlingnotes', { onChange: handleInputChange })}
                    // {...register({ required: true })}
                    name="postage_handlingnotes"
                    // onChange={handleInputChange}
                    disabled={isFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_RAFFLE_POSTAGE_HANDLING_NOTES}</label>
                  {errors.postage_handlingnotes && <span className="text-danger">{Errors.mandatory}</span>}
                </FormGroup>
                <div className="d-flex align-items-center mb-4">
                  <h5 className="fw-normal">{OKTION_VARIABLE?.OKTION_FEATURED_LOT}</h5>
                  <div id="payment" type="button" className="question-icon ms-3 p-0">
                    ?
                  </div>

                  {/* <div id="visibility1" className="question-icon ms-3">
                    ?
                  </div> */}
                  <UncontrolledPopover
                    placement="bottom"
                    className="tax-detail"
                    // isOpen={popoverOpen}
                    target="payment"
                    // toggle={handleToggle}
                    trigger="legacy">
                    <PopoverBody>
                      <p>
                        <strong>{OKTION_VARIABLE?.OKTION_FEATURED_LOT}</strong>
                      </p>
                      <p>{OKTION_VARIABLE?.OKTION_LOT_APPEAR_AT_TOP}</p>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <div className="switchBtn">
                    <ToggleSwitch
                      onChange={handleSwitchValue}
                      defaultValue={switchValue}
                      disabled={isFieldDisable || isEventStatus === 'Closed' ? true : false}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <h5 className="fw-normal">{OKTION_VARIABLE?.OKTION_RAFFLE_NOTIFY_USER}</h5>
                  {/*   <Button id="visibilitytr" type="button" className="question-icon ms-3 p-0">
                    ?
                  </Button> */}
                  {/* <div id="visibility2" className="question-icon ms-3">
                    ?
                  </div> */}
                  <UncontrolledPopover
                    placement="top"
                    className="tax-detail"
                    // isOpen={popover_Open}
                    target="visibilitytr"
                    trigger="legacy"
                    // toggle={handle_Toggle}
                  >
                    <PopoverBody>
                      <p>
                        <strong>{OKTION_VARIABLE?.OKTION_DUPLICATE_LOT}</strong>
                      </p>
                      <p>{OKTION_VARIABLE?.OKTION_MORE_LOTS}</p>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <div className="switchBtn">
                    <ToggleSwitch
                      onChange={handleSwitchVal}
                      defaultValue={switchVal}
                      disabled={isFieldDisable || isEventStatus === 'Closed' ? true : false}
                    />
                  </div>
                </div>
                <div className="focus_field">
                  <input
                    // {...register({ required: true })}
                    {...register('duplicateLot', {
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                        handleInputChange(e);
                      },
                    })}
                    name="duplicateLot"
                    // className="mb-0"
                    // type="text"
                    placeholder={OKTION_VARIABLE?.OKTION_DUPLICATE_LOT}
                    className={`mb-0 form-control ${errors.duplicateLot && 'errorColor'}`}
                    disabled={isFieldDisable}
                    id="visibilitytr"
                    type="number"
                    // onChange={e => {
                    //   const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                    //   e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                    //   handleInputChange(e);
                    // }}
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_DUPLICATE_LOT}</label>
                  <span className="leave-info-text mb-2">{OKTION_VARIABLE?.OKTION_LEAVE_BLANK}</span>
                  {errors.duplicateLot && <span className="text-danger">{Errors.mandatory}</span>}
                </div>
                {switchVal !== null && switchVal !== undefined && switchVal === true && (
                  <FormGroup
                    className={
                      watch('messageUsers') !== '' && typeof watch('messageUsers') !== 'undefined'
                        ? ' mb-0 mt-2 form-group-style focus_field fixed-fieldset'
                        : ' mb-0 mt-2 form-group-style focus_field '
                    }>
                    <textarea
                      {...register('messageUsers', { onChange: e => handleInputChange(e) })}
                      type="text"
                      name="messageUsers"
                      className={`input_field-style form-control ps-3 m-0`}
                      placeholder={OKTION_VARIABLE?.OKTION_RAFFLE_MESSAGE_USERS}
                      // onChange={e => handleInputChange(e)}
                      style={{ height: '90px' }}
                      data-gramm_editor="false"
                    />
                    <Label className="label-helper">{OKTION_VARIABLE?.OKTION_RAFFLE_MESSAGE_USERS}</Label>
                    <div className="messageBoxLot">
                      <img src={images.SendIcon} alt="sendIcon" style={{ float: 'right' }} />
                    </div>
                    {/* {errors.messageUsers && <div className="text-danger w-100">{Errors.mandatory}</div>} */}
                  </FormGroup>
                )}
                <div className="mb-5 text-center modalFooter">
                  <Row className="ps-0 pe-0">
                    {/* {isUpdate ? (
                <> */}
                    {isUpdate && (
                      <Col sm="4">
                        <Button
                          className="app-button black-button mt-2 me-2 mWidth-100"
                          disabled={isFieldDisable || isEventStatus === 'Closed' ? true : false}
                          // onClick={value => handleSubmit(onSubmit)('deleted')}>
                          onClick={toggleLotDeleteModal}>
                          {OKTION_VARIABLE?.OKTION_FEATURE_DELETE}
                        </Button>
                      </Col>
                    )}
                    <Col sm={isUpdate ? '4' : '6'}>
                      {isUpdate && data?.status === 'published' ? (
                        <Button
                          className="app-button main-button mt-2 me-2 mWidth-100"
                          disabled={
                            eventFeatureStatus() === 'Closed' ? false : data?.isSold ? false : true ? true : false
                          }
                          onClick={() => handleMarkAsPaid()}>
                          {OKTION_VARIABLE?.OKTION_MARK_PAID}
                        </Button>
                      ) : (
                        <Button
                          className="app-button main-button mt-2 me-2 mWidth-100"
                          disabled={
                            data?.status !== 'published' && (isFieldDisable || isEventStatus === 'Closed')
                              ? true
                              : false
                          }
                          onClick={value => onSubmit('', 'saved')}>
                          {OKTION_VARIABLE?.OKTION_BUTTON_SAVE}
                        </Button>
                      )}
                    </Col>
                    <Col sm={isUpdate ? '4' : '6'}>
                      <Button
                        onClick={value => {
                          image_Length?.length === 0 ? setImage(true) : handleSubmit(onSubmit)('published');
                        }}
                        // onClick={value => {
                        //   handleSubmit(onSubmit)('published');
                        //   // scrollToRef(myRef);
                        // }}
                        disabled={
                          isFieldDisable || isEventStatus === 'Closed'
                            ? isClaimedStatus || isMarkPaidStatus
                              ? false
                              : true
                            : false
                        }
                        className="app-button blue-button mt-2 me-2 mWidth-100">
                        {OKTION_VARIABLE?.OKTION_BUTTON_PUBLISH}
                      </Button>
                    </Col>
                    {/* </>
              ) : (
                <>
                  <Col sm="6">
                    <Button
                      className="app-button main-button mt-2 me-2 mWidth-100"
                      onClick={value => handleSubmit(onSubmit)('saved')}>
                      Save
                    </Button>
                  </Col>
                  <Col sm="6">
                    <Button
                      onClick={value => handleSubmit(onSubmit)('published')}
                      className="app-button blue-button mt-2 me-2 mWidth-100">
                      Save & Publish
                    </Button>
                  </Col>
                </>
              )} */}
                  </Row>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
        <DeleteLotModal
          isToggle={deleteModalOpen}
          toggle={toggleLotDeleteModal}
          // handleSubmit={value => handleSubmit(onSubmit)('deleted')}
          handleSubmit={value => {
            // handleSubmit(onSubmit)('deleted');
            onSubmit('', 'deleted');
            setTimeout(() => {
              setDeleteModalOpen(false);
            }, 2000);
            // setTimeout(() => {
            //   closeModal();
            // }, 3000);
            // EventEmitter.dispatch('auctionDeletedLot', value);
          }}
        />
        <MarkAsReadModal
          isToggle={toggleVal}
          toggle={handleMarkAsPaid}
          handleAmountChange={handleAmountChange}
          handleConfirm={handleConfirm}
          currency={currency}
          markAsPaid={data?.markAsPaid}
          amountPaid={data?.amountPaid}
        />
        <LotLimiteModal isToggle={islimitToggle} closeModal={() => setLimitToggle(!islimitToggle)} />
      </div>
    </>
  );
};

const mapStateToProps = ({ featureReducer }) => {
  return {
    featureAuctionData: featureReducer.featureRaffleData?.data?.eventFeatures,
  };
};

export default connect(mapStateToProps, { getFeatureData, dashBoardData })(AddItemModal);
